.wrapper {
  height: 100%;
  max-width: 100%;
  align-self: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: 250px;
  min-width: 200px;
  width: 100%;
  height: 320px;
  border: 2px gray solid;
  border-radius: 5px;
  overflow: hidden;
}
.cardDate {
  width: 100%;
  height: 20px;
  text-align: center;
  font-size: 16px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  background-color: pink;
  border-bottom: 1px solid gray;
}
.cardContent {
  justify-self: center;
  width: 100%;
  height: 100%;
  background-color: #c7d4c8;
}
.cardItem {
  width: 100%;  
  vertical-align: middle;
  text-align: center;

}
.calendar {
  height: calc(100% - 32px) !important;
}
@media screen and (max-width: 1024px) {
  :global(.rbc-toolbar){
    flex-direction: column;
  }
}