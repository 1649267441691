[class].headerWrapper {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 0;
}
[class].logoImg {
  height: 100px;
  width: 100px;
  margin-left: 20px;
}
[class].menu {
  width: 100%;
}
[class].layoutWrapper {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
}
.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
.childrenWrapper{
  overflow-y: auto;
  justify-content: center;
  min-height:calc(100% - 65px);
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
}
