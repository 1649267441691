.wrapper{
    display: flex;
    overflow: hidden;
    flex-direction: column;
}
.datePickerWrapper{
    display: flex;
    flex-direction: row;
}
.dateInfo{
    text-align: center;
}
.datePicker{
    width: 40px;
}
.dayCard{
    padding: 5px;
    width: 60px;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 2px;

}
.table{
    position: relative;
    left: 0px;
    width: 100%;
}
.calendar {
    max-width: 300px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
}
.activeDate{
    background-color: green;
}